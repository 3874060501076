import $ from "jquery";
import "bootstrap";
import "bootstrap-datepicker";
import Masonry from 'masonry-layout';
import jQueryBridget from 'jquery-bridget';
import imagesLoaded from 'imagesloaded';
// import Lazy from 'jquery-lazy';

jQueryBridget('masonry', Masonry, $);
jQueryBridget( 'imagesLoaded', imagesLoaded, $ );


import UIBindings from "./ui-bindings";

class PageTimer {
    getLoadTime = function () {
        var now = new Date().getTime();
        // Get the performance object
        window.performance = window.performance || window.mozPerformance || window.msPerformance || window.webkitPerformance || {};
        var timing = performance.timing || {};
        var ms = now - timing.navigationStart;
        return Math.round(ms / 10) / 100;
    }

    logToConsole = function () {
        let loadTime = this.getLoadTime();
        $(window).on('load', function () {
            console && console.info && console.info(`Client loaded in ${loadTime}s`);
        });
    }

    append = function (holder) {
        let loadTime = this.getLoadTime();
        $(window).on('load', function () {
            holder.text(' | LT: ' + loadTime() + 's');
        });
    }
}

class StickyFooter {
    constructor($wrap, $footer) {
        this.$window = $(window);
        this.$wrap = $($wrap);
        this.$footer = $($footer);
    }

    updateWrapperCSS = function () {
        let footerHeight = this.$footer.outerHeight();
        this.$wrap.css({
            marginBottom: -1 * footerHeight,
            paddingBottom: footerHeight
        });
    }

    bindOnResize = function () {
        this.$window.on('resize', this.updateWrapperCSS.bind(this));
        return this;
    }

    bindOnLoad = function () {
        this.$window.on('load', this.updateWrapperCSS.bind(this));
        return this;
    }

    init = function () {
        this.updateWrapperCSS();
        this.bindOnLoad();
        this.bindOnResize();
        return this;
    }
}

(new PageTimer()).logToConsole();
(new StickyFooter($('#container'), $('#footer'))).init();

const UIBinding = new UIBindings();

UIBinding.wowInit();
UIBinding.formSubmitButton();
UIBinding.magnificPopup();
UIBinding.slickCarourel();
UIBinding.share();
UIBinding.onScroll();
UIBinding.masonry();
UIBinding.scrollToTop();

 

