import $ from "jquery";
export class ShareHandler {
  links = {
    'google-plus': (opt) => {
      return {
        url: "https://plus.google.com/share?" + this.buildHttpQuery({
          hl: opt.lang,
          url: opt.url
        })
      };
    },
    facebook: (opt) => {
      return {
        url: "http://www.facebook.com/sharer/sharer.php?" + this.buildHttpQuery({
          u: opt.url,
          t: opt.text
        }),
        width: 900,
        height: 500
      };
    },
    twitter: (opt) => {
      return {
        url: "https://twitter.com/intent/tweet?" + this.buildHttpQuery({
          text: opt.text,
          url: opt.url,
          via: opt.via
        }),

        width: 650,
        height: 360
      };
    },
    linkedin: (opt) => {
      return {
        url: 'https://www.linkedin.com/cws/share?' + this.buildHttpQuery({
          url: opt.url,
          isFramed: 'true'
        }),
        width: 550,
        height: 550
      };
    },
    pinterest: (opt) => {
      return {
        url: 'http://pinterest.com/pin/create/button?' + this.buildHttpQuery({
          url: opt.url,
          description: opt.text,
          media: opt.image
        }),
        width: 700,
        height: 300
      };
    }
  };
  opts = {
    url: '',
    text: '',
    image: ''
  };

  stacked = false;
  container = null;
  platforms = [];

  constructor($selector = $('[data-share-default]')) {
    this.container = $selector;
    this.opts = {
      url: $selector.data('url'),
      text: $selector.data('text'),
      image: $selector.data('image')
    }
    this.stacked = $selector.data('stacked');
    if ($selector.data('platforms') !== undefined) {
      this.platforms = $selector.data('platforms')
    }
  }

  build() {
    console.log(this.platforms);
    $.each(this.platforms, (i, item)=>{
      
       console.log(i, item);
       this.createShare(i, item.icon, item.colour, item.font);

    })

  }

  createShare(platform, icon, colour, font) {
    let link = `
      <a class="btn">
        <i class="fab fa-${icon} fa-${colour} ${colour}"></i>
      </a>
    `;

    if (this.stacked) {
      link = `
        <a class="btn">
          <span class="fa-stack fa-${font}">
            <i class="fa fa-circle fa-stack-2x" style="color:${colour}"></i>
            <i class="fab fa-${icon} fa-circle fa-md fa-stack-1x fa-inverse" ></i>
          </span>
        </a>
      `;
    }

    let button = $(link);
    button.on('click', (e) => {
      e.preventDefault();
      this.share(platform);
    });

    this.container.append(button);
    return button;
  }

  buildHttpQuery(data) {
    var copy = {};

    $.each(data, function (k, v) {
      if (v) {
        copy[k] = v;
      }
    });

    return $.param(copy);
  }

  share(platform) {
    console.log('share', platform, this.links[platform](platform));
    var url = this.opts.url ? this.opts.url : window.location.href;
    var shareWindow = window.open(
      "",
      "share" + platform,
      "toolbar=0, status=0, width=" + 50 + ", height=" + 50 + ", top=" + 0 + ", left=" + 0
    );

    if (window.location.href !== url) {
      // show loader
      var shareLoader = $('<div id="shareLoader"></div>');
      var fade = $('<div style="position: fixed; left: 0; top: 0; right: 0; bottom: 0; background: rgba(0,0,0,0.5);"></div>');
      var spinner = $('<i class="fa fa-cog fa-spin" style="color: #fff"></i>');
      spinner.css({ position: 'fixed', top: '50%', left: '50%' });
      fade.append(spinner);
      shareLoader.append(fade);

      $('body').append(shareLoader);

      $.ajax(url, {
        async: true,
        success: (data) => {
          shareLoader.remove();
          this.sharePage(shareWindow, platform, url, $('<root/>').append(data));
        },
        error: () => {
          shareLoader.remove();
          alert('Failed to load URL: ' + url);
        }
      });
    } else {
      this.sharePage(shareWindow, platform, url, $(document));
    }
  }

  sharePage(shareWindow, platform, url, $page){
    var shareProps = $.extend({}, this.opts);

    shareProps.platform = platform;

    if (shareProps.text === undefined) {
      shareProps.text = $page.find('meta').filter('[property="article-title"]').attr('content');
    }
    if (shareProps.via === undefined) {
      shareProps.via = $page.find('#twitterHandle').attr('content');
    }
    if (shareProps.lang === undefined) {
      shareProps.lang = $page.find('html').attr('lang');
    }
    if (shareProps.image === undefined) {
      shareProps.image = $page.find('meta').filter('[property="og:image"]').attr('content');
    }

    var text = shareProps.text;


    if (platform == 'twitter') {
      var startLen = 23 + 3; // twitter short URL length + 3
      if (shareProps.via) {
        startLen += (' via @' + shareProps.via).length;
      }
      if (text.length + startLen > 140) {
        var diff = (text.length + startLen) - 140;
        text = text.substr(0, text.length - diff) + '…';
      }
    }

    var popupConf = this.links[platform]({
      url: url,
      text: text,
      via: shareProps.via,
      image: shareProps.image
    });

    this.openPopup(shareWindow, popupConf);
  }

  openPopup (shareWindow, conf) {
    var top = Math.round((screen.height - conf.height) / 2);
    var left = Math.round((screen.width - conf.width) / 2);

    shareWindow.location.href = conf.url;
    shareWindow.focus();
    shareWindow.resizeTo(conf.width, conf.height);
    shareWindow.moveTo(left, top);
  }
}


//   this.createButton = function (platform) {
//     var button = $('<a class="share-btn share-btn-' + platform + '"><i class="fa fa-' + platform + ' fa-lg" ></i></a>');

//     button.css({cursor: 'pointer'});

//     button.on('click', function (e) {
//       e.preventDefault();
//       self.share(platform);
//     });

//     $container.append(button);

//     return button;
//   };

// function ShareHandlerd($container) {
//   //var query = $.param(params);

//   var definedProps = {
//     url: $container.data('url'),
//     text: $container.data('text')
//   };



  // var openPopup = function (shareWindow, conf) {
  //   var top = Math.round((screen.height - conf.height)/2);
  //   var left = Math.round((screen.width - conf.width)/2);

  //   shareWindow.location.href = conf.url;
  //   shareWindow.focus();
  //   shareWindow.resizeTo(conf.width, conf.height);
  //   shareWindow.moveTo(left, top);
  // };


//   var sharePage = function (shareWindow, platform, url, $page) {
    // var shareProps = $.extend({}, definedProps);

    // shareProps.platform = platform;

    // if (!shareProps.text) {
    //   shareProps.text = $page.find('meta').filter('[property="article-title"]').attr('content');
    // }
    // if (!shareProps.via) {
    //   shareProps.via = $page.find('#twitterHandle').attr('content');
    // }
    // if (!shareProps.lang) {
    //   shareProps.lang = $page.find('html').attr('lang');
    // }
    // if (!shareProps.image) {
    //   shareProps.image = $page.find('meta').filter('[property="og:image"]').attr('content');
    // }

    // var text = shareProps.text;

    // if (platform == 'twitter') {
    //   var startLen = 23 + 3; // twitter short URL length + 3
    //   if (shareProps.via) {
    //     startLen += (' via @' + shareProps.via).length;
    //   }
    //   if (text.length + startLen > 140) {
    //     var diff = (text.length + startLen) - 140;
    //     text = text.substr(0, text.length - diff) + '…';
    //   }
    // }

    // var popupConf = popups[platform]({
    //   url: url,
    //   text: text,
    //   via: shareProps.via,
    //   image: shareProps.image
    // });

    // openPopup(shareWindow, popupConf);
//   };

  // this.share = function (platform) {
  //   var url = definedProps.url ? definedProps.url : window.location.href;

   

  // };

//   this.appendFacebook = function () {
//     $container.append(self.createButton('facebook'));
//   };

//   this.appendGoogle = function () {
//     $container.append(self.createButton('google-plus'));
//   };

//   // TODO test
//   this.appendLinkedIn = function () {
//     $container.append(self.createButton('linkedin'));
//   };

//   // TODO test / enable
//   this.appendPinterest = function () {
//     $container.append(self.createButton('pinterest'));
//   };
// }
