import $ from "jquery";
import "magnific-popup";
import "slick-carousel";
import Masonry from 'masonry-layout';
import jQueryBridget from 'jquery-bridget';
import imagesLoaded from 'imagesloaded';
import Layzr from 'layzr.js'

jQueryBridget('masonry', Masonry, $);
jQueryBridget( 'imagesLoaded', imagesLoaded, $ );
import WOW from 'wowjs'
import { ShareHandler } from "./share";
export default class UIBindings {
  log() {
    console.log('binding');
  }
  formSubmitButton() {
    $(document).on('submit', 'form', function () {
      let submitText = $(this).find('[data-submit-text]');
      let submittingText = $(this).find('[data-submitting-text]');
      let btn = submitText.closest('button');

      submittingText.removeClass('d-none');
      submitText.addClass('d-none');
      btn.prop('disabled', true);
    });
  }
  magnificPopup () {
    let galleryTrigger = $('[mfp-type="gallery"]');
    let gallery = $('.gallery');
    if (galleryTrigger.length > 0) {

      galleryTrigger.on('click', function (e) {
        e.preventDefault();
        console.log('target', $(this).data('target'));
        $($(this).data('target')).magnificPopup('open');
      });

      if (gallery.length > 0) {

        $.each(gallery, function(){

          $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            gallery: {
              enabled: true,
              navigateByImgClick: true
            },
            fixedContentPos: false
          });
        })
      }
    }
  }

  slickCarourel() {
    $(".count").each(function(i) {
      $(this).find("span.counter").text(++i);
    });
    $('[data-slick]').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots:true,
      autoplay: true,
      autoplaySpeed: 3000,
      dotsClass:"custom-dots",
      customPaging:function(slider, i){
        let slideNumber = (i + 1),
        totalSlides = slider.slideCount;
        return '<a class="dot text-center my-auto mx-auto text-center" role="button"> </a>';
      },
      arrows:false,
      prevArrow: '<a href="#" class="carousel-control-prev  zindex-1" role="button"><span class="carousel-control-prev-icon" aria-hidden="true"></span><span class="sr-only">Previous</span></a>',
      nextArrow: '<a href="#" class="carousel-control-next " role="button"><span class="carousel-control-next-icon" aria-hidden="true"></span><span class="sr-only">Next</span></a>',

    });

    $('[data-slide]').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots:true,
      dotsClass:"custom-dots",
      customPaging:function(slider, i){
        let slideNumber = (i + 1),
        totalSlides = slider.slideCount;
        return '<a class="dot text-center my-auto mx-auto text-center" role="button"> </a>';
      },
      arrows:true,
      prevArrow: '<a href="#" class="carousel-control-prev  zindex-1" role="button"><span class="carousel-control-prev-icon" aria-hidden="true"></span><span class="sr-only">Previous</span></a>',
      nextArrow: '<a href="#" class="carousel-control-next " role="button"><span class="carousel-control-next-icon" aria-hidden="true"></span><span class="sr-only">Next</span></a>',

    });

    $('[data-blog]').slick({
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      dots:true,
      dotsClass:"custom-travel",
      customPaging:function(slider, i){
        let slideNumber = (i + 1),
        totalSlides = slider.slideCount;
        return '<a class="dot text-center my-auto mx-auto text-center" role="button"> </a>';
      },
      arrows:false,
      prevArrow: '<a href="#" class="carousel-control-prev small-arrow  zindex-1" role="button"><span class="carousel-control-prev-icon" aria-hidden="true"></span><span class="sr-only">Previous</span></a>',
      nextArrow: '<a href="#" class="carousel-control-next small-arrow" role="button"><span class="carousel-control-next-icon" aria-hidden="true"></span><span class="sr-only">Next</span></a>'
    });
  }


  share() {
    const shareHandler = new ShareHandler();
    console.log('load sharing module');
    shareHandler.build();

  }
  masonry(){
    // $('.grids').masonry({
    //   itemSelector: '.grid-item',
    //   columnWidth: 100%
    // });
  }
  wowInit(){

    const instance = Layzr()
    console.log('Module assigned to window.Layzr. Logged below:')
      console.log(Layzr)

    if ($(".blogBox:hidden").length != 0) {
      $("#loadMore").show();
    }   
    $("#loadMore").on('click', function (e) {
      e.preventDefault();
      $(".moreBox:hidden").slice(0, 6).slideDown();
      if ($(".moreBox:hidden").length == 0) {
        $("#loadMore").fadeOut('slow');
      }
    });

    $(".wow").each(function() {

      $(window).on('scroll', function(){
       let winTop = $(window).scrollTop();
       let sTop = $('.wow').offset().top ;
       let total = sTop - winTop;
       let wowHeight = $(this).height()  ;

       if (total > wowHeight) {
             // $(this).attr("data-wow-offset", wowHeight);
           }
           console.log('total',total);
           console.log('wowHeight',wowHeight);
         })

      new WOW.WOW().init();
    });

  }

  scrollToTop(){

   let scrollerTop = $('.scrollTop');

   $(window).on('scroll', function(){

    let scroller = $(this).scrollTop();

    if (scroller > 400) {
      scrollerTop.css('opacity','1');
    }else{
     scrollerTop.css('opacity','0');
   }

 });
   scrollerTop.click(function(){
    $('html, body').animate({
      scrollTop:0
    }, 'slow');
  });

 }


 onScroll(){
  // $("img.lazy").Lazy();

  let  mainCont = $('.mainCont');
  let  sideMiniMenu = $('.mini-menu');
  let mainMenu = $('#SideMenu');
  let sideMenuH = mainMenu.height();

  mainCont.each(function(i ){
    let counts = $(this).data('count');
    let countsTop = $(this).offset();
    if (counts % 2 == 0) {
      $(this).addClass('even');
    }else{
     $(this).addClass('odd');
   }
 });

  let stickyToggle = function (sticky, stickyWrapper, scrollElement) {
    let stickyHeight = sticky.outerHeight();
    let stickyTop = stickyWrapper.offset().top;
    if (scrollElement.scrollTop() >= stickyTop) {
      sticky.addClass("is-sticky");
      //$('.place-holder').css('height', '300px');
      $('.place-holder').addClass('col-md-3');
      $('.mini-menu').addClass('shadow');
      //$('.main-container').addClass('offset-md-3  pl-3');
      
    }else {
      sticky.removeClass("is-sticky");
      $('.place-holder').css('height', '0px');
      $('.place-holder').removeClass('col-md-3');
      $('.mini-menu').removeClass('shadow');
      //$('.main-container').removeClass('offset-md-3');
    }
  };


  $('[data-toggle="sticky-onscroll"]').each(function () {
    let sticky = $(this);

    let stickyWrapper = $('<div>').addClass('sticky-wrapper'); 
    sticky.before(stickyWrapper);
    sticky.addClass('sticky');

    $(window).on('scroll.sticky-onscroll resize.sticky-onscroll', function () {
      stickyToggle(sticky, stickyWrapper, $(this));

    });

    stickyToggle(sticky, stickyWrapper, $(window));
  });

  $.fn.scrollTo = function(elem) { 
    $(this).scrollTop($(this).scrollTop() - $(this).offset().top + $(elem).offset().top ); 
    return this; 
  };


  var type = window.location.hash.substr(1);

  if (type.length > 0) {
   var listElements = $( "#" + type + '-item');
   var listId = $( "#" + type + '-title');
   console.log(listElements,listId);

   listElements.addClass('active-menu');
   listId.addClass('active-menu');
   sideMiniMenu.scrollTo(listElements, 500);
 }

 

 $(window).on('scroll', function(){

  $('.mainCont').each(function() {

    if($(window).scrollTop() >= $(this).offset().top - 200 ) {
      var id = $(this).attr('id');

      var current = $('#'+ id + '-item');
      var currentTitle = $('.'+ id + '-title');
      $('.mini-menu span').removeClass('active-menu');
      $('.menu-title').removeClass('active-menu'); 

      current.addClass('active-menu');
      currentTitle.addClass('active-menu'); 
      var target_offset = current.offset();
      var target_top = target_offset.top;
      sideMiniMenu.scrollTo(current);
    }

  });
});

 $('a[href^="#"]').on('click', function(event) {
  var target = $(this.getAttribute('href'));
  if( target.length ) {
    event.preventDefault();
    $('html, body').stop().animate({
      scrollTop: target.offset().top
    }, 'fast');
  }
});

}
share() {
  const shareHandler = new ShareHandler();
  console.log('load sharing module');
  shareHandler.build();




}
}